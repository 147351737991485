<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Crear recurso video</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form">
            <!--<el-form-item label="Curso">
                 <select v-model="form.title" multiple class="width-100">
                    <option v-for="itemWork in listItemsCoursesTitle" :key="itemWork.id">
                      {{itemWork}}
                    </option>
                  </select>
              </el-form-item>-->
                  <el-form-item label="Curso">
                <ValidationProvider name="course" rules="required" v-slot="{ errors }">
                  <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.course_id"
                  filterable
                  placeholder="título">
                    <el-option
                      v-for="itemCourses in listItemsCoursesTitle"
                      :key="itemCourses.value"
                      :label="itemCourses.title"
                      :value="itemCourses._id">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
          </el-form-item>
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Descripcion">
                <tinyeditor v-model="form.description"
                  api-key='epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex'
                    :init="{
                      height: 250,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }"
                  />
              </el-form-item>
              <el-form-item label="Duración">
                <ValidationProvider name="duration" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.duration"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="URL de video">
                <ValidationProvider name="url video" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.video"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Orden" size="mini">
                <ValidationProvider name="order" rules="required" v-slot="{ errors }">
                  <el-input-number v-model="form.order" controls-position="right" :min="0"></el-input-number>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
               <el-form-item label="Estatus">
                <el-switch
                  v-model="form.status"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </el-form-item>
              <!-- <el-form-item label="Estatus">
                <ValidationProvider name="Status" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.status"
                  filterable
                  allow-create
                  placeholder="Seleccionar">
                    <el-option
                      v-for="item in optionStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>
                <span>Si el estatus es <strong>No</strong> el artículo no estara visible dentro del sitio</span>
              </el-form-item>-->
              <el-form-item>
              <el-button
                @click.stop.prevent="onStore"
                type="primary"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Crear</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeResources } from '@/api/resources'
import { uploadImageFile } from '@/api/image'
import { getCourseTitle } from '@/api/listItems'

export default {
  data () {
    return {
      limitTitle: 65,
      limitDescription: 155,
      form: {
        course_id: '',
        type: 'video',
        title: '',
        description: '',
        duration: '',
        video: '',
        order: 0,
        status: true,
        image: '',
        file: '',
        content: ''
      },
      listItemsCoursesTitle: [],
      optionStatus: [{
        value: true,
        label: 'Si'
      }, {
        value: false,
        label: 'No'
      }],
      optionsCourses: [{
        value: 'Otro',
        label: 'Otro'
      }, {
        value: 'Marketing',
        label: 'Marketing'
      }, {
        value: 'Ventas',
        label: 'Ventas'
      }, {
        value: 'Finanzas',
        label: 'Finanzas'
      }, {
        value: 'Apps',
        label: 'Apps'
      }],
      loading: false,
      loadingDelete: false,
      isVisibleCollection: false,
      formEditCollection: {}
    }
  },
  created () {
    this.form.admin_id = this.$store.getters.id
    this.listResourcesTitle()
  },
  methods: {
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '1200')
      formData.append('height', '900')
      formData.append('title', 'Blog')
      formData.append('alt', 'SafeApp-image-blog')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    async onStore () {
      await storeResources(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Se ha publicado un nuevo Video!',
            type: 'success'
          })
          this.$router.push({ name: 'resources' })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listResourcesTitle () {
      this.loading = true
      await getCourseTitle()
        .then(response => {
          console.log(response)
          this.listItemsCoursesTitle = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    },
    handleAvatarSuccess (res, file) {
      // this.form.image = 'https://safeapp.webdecero.dev/' + res
      this.form.image = res
      console.log(this.form.image)
    }
  },
  computed: {
    limitTitleCount () {
      return this.limitTitle - this.form.titleSeo.length
    },
    limitDescriptionCount () {
      return this.limitDescription - this.form.descriptionSeo.length
    }
  }
}
</script>
